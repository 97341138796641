import { RootState } from 'store';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { IFinishEffectField } from 'order/wizard/orderStyles/interface/FinishEffectField';
import { SpecificationsOptionsEnum } from 'order/wizard/orderStyles/enums/SpecificationsOptionsEnum';
import { StyleSpecifications } from 'order/wizard/orderStyles/interface/StyleSpecifications';
import { StylesStepsEnum } from 'order/wizard/orderStyles/enums/StylesStepsEnum';

import { FormLabel } from 'shared/components/FormLabel';
import { Spacer } from 'shared/components/Layout';
import { TabbableButton } from 'shared/components/Button';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import UtilService from 'shared/services/util.service';

import FinishEffectFieldNew from './FinishEffectFieldNew';

import {
  getAllFinishEffectsByFinishEffects,
  getFinishEffectsByColor,
  setFinishEffectOptions,
} from '../../../store/specifications/orderStylesSpecificationActions';

const FinishEffectNewContainer = styled.div``;

const TabbableButtonStyled = styled(TabbableButton)`
  color: ${({ theme }) => theme.lynch};

  &:hover {
    color: ${({ theme }) => theme.chambray};
  }
`;

export const generateNewFinishEffectField = (
  id: string,
  hasSpecialFinishSample?: boolean
) => {
  return {
    id,
    orderNumber: +id,
    selectedOption: null,
    showAvailableOnly: hasSpecialFinishSample ?? false,
    upcharge: '',
  } as IFinishEffectField;
};

const FinishEffectNew = () => {
  const dispatch = useAppDispatch();

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const storedStyle = useSelector(
    (state: RootState) => state.orderStylesReducer.style
  );

  const storedFinishEffects = useSelector(
    (state: RootState) => state.orderStylesReducer.specifications?.finishEffects
  );

  const { control, formState, watch, setValue, reset, getValues } =
    useFormContext<StyleSpecifications>();

  const finishColorWatched = watch('finishColor');
  const specificationOptionWatched = watch('specificationOption');
  const specialFinishSampleWatched = watch('specialFinishSample');

  const hasSpecialFinishSample = specialFinishSampleWatched?.length > 0;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'finishEffects',
  });

  const loadFinishEffectOptions = () => {
    const storedFinishEffectFirst = storedFinishEffects?.find(
      (finishEffect) => finishEffect.orderNumber === 1
    );

    if (
      !storedStyle?.finishColor ||
      !storedStyle?.productLine ||
      (!storedStyle?.woodMaterial && !storedStyle.materialGroup)
    )
      return;

    dispatch(
      getFinishEffectsByColor({
        finishColorId: storedStyle.finishColor.id,
        productLineId: storedStyle.productLine.id,
        woodMaterialId:
          storedStyle.materialGroup?.woodMaterialId ??
          storedStyle.woodMaterial.id,
        showOnlyAvailable: storedFinishEffectFirst?.showAvailableOnly ?? false,
        specialFinishSample: storedStyle.specialFinishSample || undefined,
      })
    );

    if ((storedFinishEffects?.length ?? 0) > 0) {
      dispatch(
        getAllFinishEffectsByFinishEffects({
          finishColorId: storedStyle.finishColor.id,
          productLineId: storedStyle.productLine.id,
          woodMaterialId:
            storedStyle.materialGroup?.woodMaterialId ??
            storedStyle.woodMaterial.id,
          finishEffects: storedFinishEffects!,
          specialFinishSample: storedStyle.specialFinishSample || undefined,
        })
      );
    }
  };

  const resetAllFinishEffects = () => {
    setValue('finishEffects', [
      generateNewFinishEffectField('1', hasSpecialFinishSample),
    ]);

    dispatch(setFinishEffectOptions(null));
  };

  useEffect(() => {
    if (storedStyle && !formState.isDirty) {
      loadFinishEffectOptions();
    }
  }, [storedStyle]);

  useEffect(() => {
    if (
      UtilService.dirtyOrTouchedFields(formState, [
        'woodOrMaterial',
        'specialFinishSample',
        'showAvailableFinishesOnly',
        'finishColor',
      ])
    ) {
      resetAllFinishEffects();
    }
  }, [finishColorWatched]);

  useEffect(() => {
    if (
      (storedStyle?.step ?? 0) === +StylesStepsEnum.SPECIFICATIONS &&
      fields.length === 0 &&
      !formState.isDirty
    ) {
      reset({
        ...getValues(),
        finishEffects: [
          generateNewFinishEffectField('1', hasSpecialFinishSample),
        ],
      });
    }
  }, [storedStyle, fields]);

  useEffect(() => {
    if (
      formState.isDirty &&
      fields.length === 0 &&
      specificationOptionWatched === SpecificationsOptionsEnum.CONFIGURE_WOOD
    ) {
      append(generateNewFinishEffectField('1', hasSpecialFinishSample));
    }
  }, [specificationOptionWatched]);

  return (
    <>
      <FinishEffectNewContainer>
        <>
          <FormLabel>Finish Effect</FormLabel>
          <Spacer h="20px" />
        </>

        {fields?.map((finishEffectField) => (
          <FinishEffectFieldNew
            control={control}
            remove={remove}
            index={+finishEffectField.id - 1}
            key={finishEffectField.id}
          />
        ))}

        <TabbableButtonStyled
          onClick={() =>
            append(
              generateNewFinishEffectField(
                (fields.length + 1).toString(),
                hasSpecialFinishSample
              )
            )
          }
          disabled={fields.length >= 3 || !canEdit}
          type="button"
        >
          + Add another finish effect
        </TabbableButtonStyled>
      </FinishEffectNewContainer>

      <Spacer h="30px" />
    </>
  );
};

export default FinishEffectNew;

import parse from 'html-react-parser';
import styled, { css } from 'styled-components';
import { DraggableProvided } from 'react-beautiful-dnd';
import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router';

import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';
import { ReactComponent as AttachmentIcon } from 'assets/icons/attachment.svg';
import { ReactComponent as CommentIcon } from 'assets/icons/comment.svg';
import { ReactComponent as MoreInfoIcon } from 'assets/icons/vertical-dots.svg';
import { ReactComponent as UnreadCollaborationIcon } from 'assets/icons/unread_collaboration.svg';

import { LineItem } from 'order/wizard/orderStyles/interface/LineItem';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import {
  getOrderLineItems,
  setOrderLineItems,
  updateLineItemCL,
} from 'order/store/orderActions';

import {
  changeLineItemClientNumber,
  deleteLineItem,
  duplicateLineItem,
  setLineItemsModalOpened,
} from 'order/wizard/orderLineItems/store/orderLineItemsActions';

import {
  clearCurtain,
  getChannels,
  getTopicsByEntity,
  setActiveChannel,
  setActiveEntityID,
  setActiveEntityName,
  setActiveEntityType,
  setActiveLineItemID,
  setActiveTopic,
  setCreateTopic,
  setCurtainComments,
  setCurtainTab,
  setLineItemChannels,
  setTopicItemDefaultValue,
  setTopicTypeDefaultValue,
} from 'curtain/store/curtainActions';
import { ReactComponent as PencilIcon } from 'assets/icons/edit-solid.svg';

import { CurtainTabsEnum } from 'curtain/enums/CurtainTabsEnum';
import { IChannel } from 'curtain/interfaces/IChannel';
import { TopicType } from 'curtain/enums/TopicTypeEnum';

import {
  altoTwo,
  anzac,
  kashmirBlue,
  lynch,
  mineShaft,
  poloBlue,
  silverChaliceTwo,
  taupe,
  valencia,
} from 'shared/config/Colors';

import DragButton from 'shared/components/DragButton';
import ElipsisText from 'shared/components/ElipsisText';
import Loader from 'shared/components/Loader';
import PerFactoryBadges from 'shared/components/PerFactoryBadges';
import UtilService from 'shared/services/util.service';
import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import { ButtonClear, ButtonIcon } from 'shared/components/Button';
import { Checkbox } from 'shared/components/Checkbox';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { FloatingCheckbox } from 'shared/components/FloatingCheckbox';
import { H6, P, SPAN } from 'shared/components/Typography';
import { Info } from 'shared/components/Info';
import { Input } from 'shared/components/Input';
import { MenuWithDropdown } from 'shared/components/MenuWithDropdown';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { MoreInfoButton, MoreInfoContainer } from 'shared/components/MoreInfo';
import { PriorityBadge } from 'shared/components/PriorityBadge';
import { Spacer } from 'shared/components/Layout';
import { Tooltip } from 'shared/components/Tooltip';
import { Wrapper } from 'shared/components/Wrapper';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import {
  lineItemNumberValidation,
  onlyWholeDigitValidation,
} from 'shared/validations/validations';

import {
  OrderLineItemTestButtonEnum,
  OrderLineItemTestThreeDotMenuButtonEnum,
} from 'tests/enums/OrderLineItemTestEnums';

import { testId } from 'tests/utils';

import {
  LineItemHingePositionEnum,
  LineItemPositionEnum,
  LineItemExposedEndsPositionEnum,
} from './enums/LineItemPositionEnum';

import ImageBox from '../../../shared/components/ImageBox';
import LineItemDimup from './components/LineItemDimup';
import { ChannelTypeEnum } from '../../../curtain/enums/ChannelTypeEnum';
import { Modification } from './components/Modification';
import { OrderLineItemsModal } from './OrderLineItemsModal';
import { checkAsapItem } from '../orderASAP/store/OrderAsapActions';
import { StyleOverride } from '../orderStyles/interface/StyleOverride';

const SingleLineContainer = styled.div`
  padding-bottom: 16px;
  position: relative;
  min-width: 1100px;
`;

interface OrderLineItemProps {
  draggedItemHighlight?: boolean;
  expanded?: boolean;
  lineItem: LineItem;
  draggableProvided?: DraggableProvided;
  totalItems?: number;
  canCheck?: boolean;
  index?: number;
  onExpand?: (lineItemId: string, expanded: boolean) => void;
  changeLineItemPosition?: (
    currentPosition: number,
    newPosition: number
  ) => void;
  fromImport?: boolean;
  onLineItemClick?: (lineItem: LineItem) => void;
  selectable?: boolean;
  styleOverride?: StyleOverride | null;
}

interface OrderLineItemContainerProps
  extends Pick<OrderLineItemProps, 'draggedItemHighlight'> {
  invalid?: boolean;
  selectable?: boolean;
}

const OrderLineItemContainer = styled.div<OrderLineItemContainerProps>`
  align-items: center;
  background: ${({ theme }) => theme.white};
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: ${({ selectable }) => (selectable ? 'pointer' : 'default')};
  display: flex;
  padding: 15px 16px;
  text-align: start;
  transition: 300ms;
  width: 100%;
  ${BoxShadowCSS};

  ${({ invalid, theme }) =>
    invalid &&
    css`
      border-color: ${theme.valencia};
      background-color: ${theme.fairPink};
    `}

  &:hover,
  &.highlight {
    border: 1px solid ${({ theme }) => theme.lynch};
  }

  ${({ draggedItemHighlight, theme }) =>
    draggedItemHighlight && `border: 1px solid ${theme.lynch}`};
`;

const ExpandButton = styled(ButtonIcon)`
  svg {
    transition: transform 500ms;
    transform: rotateZ(180deg);
  }

  &.open {
    svg {
      transform: rotateZ(0);
    }
  }
`;

const ExpandButtonHolder = styled.div`
  min-width: 18px;
`;

const StyledInput = styled(Input)`
  &:not([type='checkbox']) {
    min-width: 40px;
    max-width: 40px;
    max-height: 32px;
    padding: 8px;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.mineShaft};
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.wildSandTwo};
  }
`;

const FormErrorHolder = styled.div`
  position: relative;

  div {
    position: absolute;
    white-space: nowrap;
    font-size: 0.6rem;
  }
`;

const LineCodeContainer = styled.div`
  min-width: 40px;
  width: 40px;
`;

const LineItemTitleContainer = styled.div`
  min-width: 150px;
  width: 150px;
`;

const LineItemStyleContainer = styled.div`
  min-width: 150px;
  width: 150px;
`;

const LineItemDetailsContainer = styled(Wrapper)`
  min-width: 160px;
  width: 160px;
`;

const WrapperRight = styled(Wrapper)`
  margin-left: auto;
`;

const ButtonsContainer = styled(Wrapper)`
  min-width: 50px;
  width: 50px;
`;

const LineLabelsContainer = styled(Wrapper)`
  min-width: 120px;
`;

const StaticInputValue = styled(P)`
  border-radius: 5px;
  padding: 8px;
  min-width: 40px;
  min-height: 32px;
  background-color: ${({ theme }) => theme.wildSandTwo};
`;

const IndentedContainer = styled.div`
  margin-left: 200px;
  margin-top: 8px;
`;

const OrderLineItem: FC<OrderLineItemProps> = ({
  lineItem,
  draggableProvided,
  draggedItemHighlight,
  totalItems,
  canCheck,
  index,
  changeLineItemPosition,
  onExpand,
  expanded,
  fromImport,
  onLineItemClick,
  selectable,
  styleOverride,
}: OrderLineItemProps) => {
  const dispatch = useDispatch();
  const { orderId } = useParams<OrderPageParams>();
  const location = useLocation();
  const history = useHistory();

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isReplaceModalVisible, setIsReplaceModalVisible] = useState(false);
  const [expandedLocal, onExpandLocal] = useState(true);

  const [duplicationInProgress, setDuplicationInProgress] = useState(false);

  const collaborationButtonRef = useRef<HTMLButtonElement>(null);

  const activeEntityID = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityID
  );

  const activeChannel = useSelector(
    (state: RootState) => state.curtainReducer.activeChannel
  );

  const activeLineItemID = useSelector(
    (state: RootState) => state.curtainReducer.activeLineItemID
  );

  const topicItemDefaultValue = useSelector(
    (state: RootState) => state.curtainReducer.topicItemDefaultValue
  );

  const modalOpenedForLineItemId = useSelector(
    (state: RootState) => state.orderLineItemsReducer.modalOpenedForLineItemId
  );

  const selectedLineItemsToImport = useSelector(
    (state: RootState) => state.importStylesReducer.selectedLineItems
  );

  const orderLineItemLimitNotReachedYet = useSelector(
    (state: RootState) =>
      (state.orderReducer.lineItems?.filter(
        (lineItemFilter) => lineItemFilter.styleId === lineItem.styleId
      ).length ?? 0) < 99
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const checkedLineItems = useSelector(
    (state: RootState) => state.orderAsapReducer.checkedLineItems
  );

  const orderStyles = useSelector(
    (state: RootState) => state.orderReducer.styles
  );

  const isOrderSalesMaterial = useOrderProductType(
    OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const customProductLine = useSelector((state: RootState) =>
    state.sharedReducer.productLines?.find(
      (productLine) => productLine.name === ProductLineEnums.PRODUCT_LINE_CUSTOM
    )
  );

  const lineItems = useSelector(
    (state: RootState) => state.orderReducer.lineItems
  );

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const { enableExposedEnds } = UtilService.shouldShowEnds({
    cabinetBoxMaterialName: lineItem.style?.cabinetBoxMaterial?.name,
    productLineName: orderStyles?.find(
      (style) => style.id === lineItem.style?.styleId
    )?.productLine.name,
  });

  const getDefaultNumbersForLineItem = () => {
    return {
      clientNumber: lineItem.clientLineItemNumber
        ? lineItem.clientLineItemNumber.toString()
        : '',
      number: lineItem.number.toString(),
    };
  };

  const {
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: getDefaultNumbersForLineItem(),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onFailedChangeLineItemClientNumber = () => {
    toast.error('Could not update Line Item client number.');
    setValue('clientNumber', lineItem.clientLineItemNumber.toString());
  };

  const onSuccessChangeLineItemClientNumber = (clientNumber: string) => {
    toast.success('You have successfully updated CL on the Line Item.');

    dispatch(
      updateLineItemCL({
        lineItemId: lineItem.lineItemId,
        clientNumber: +clientNumber,
      })
    );
  };

  const onSubmitClientNumber = (clientNumber: string) => {
    dispatch(
      changeLineItemClientNumber(
        {
          lineItemId: lineItem.lineItemId,
          orderId,
          clientNumber: clientNumber || null,
        },
        onFailedChangeLineItemClientNumber,
        () => onSuccessChangeLineItemClientNumber(clientNumber)
      )
    );
  };

  const onSubmitLineItemNumber = (number: string) => {
    if (changeLineItemPosition && index !== undefined) {
      changeLineItemPosition(index, +number - 1);
    }
  };

  const editLineItem = () => {
    const params = UtilService.prepareQueryParamsForLineItemModal(lineItem);
    history.replace(
      {
        pathname: location.pathname,
        search: params.toString(),
      },
      location.state
    );

    dispatch(setLineItemsModalOpened(lineItem.lineItemId));
  };

  const replaceLineItem = (close: () => void) => {
    const params = UtilService.prepareQueryParamsForLineItemModal(
      lineItem,
      true
    );

    history.replace(
      {
        pathname: location.pathname,
        search: params.toString(),
      },
      location.state
    );

    close();
    setIsReplaceModalVisible(false);
    dispatch(setLineItemsModalOpened(lineItem.lineItemId));
  };

  const onAttachmentClickHandler = () => {
    const params = UtilService.prepareQueryParamsForLineItemModal(lineItem);

    history.push(
      { pathname: location.pathname, search: params.toString() },
      {
        ...location.state,
        navigateAttachments: true,
      }
    );

    dispatch(setLineItemsModalOpened(lineItem.lineItemId));
  };

  const onSuccessDuplicateLineItem = (close: () => void) => {
    dispatch(
      getOrderLineItems({
        orderId,
        ...(isOrderSalesMaterial && {
          isSalesMaterial: true,
          productLineId: customProductLine?.id,
        }),
      })
    );
    close();
  };

  const onDuplicateHandler = (close: () => void) => {
    setDuplicationInProgress(true);
    dispatch(
      duplicateLineItem(
        { orderId, lineItemId: lineItem.lineItemId },
        () => onSuccessDuplicateLineItem(close),
        undefined,
        setDuplicationInProgress
      )
    );
  };

  const onSuccessDeleteLineItem = () => {
    setIsDeleteModalVisible(false);
    let temp: LineItem[] = [];
    const updateList = (lineItems ?? [])
      .filter((li) => li.lineItemId !== lineItem.lineItemId)
      .map((item: LineItem, liIndex) => {
        return {
          ...item,
          number: liIndex + 1,
        };
      });
    if (!orderStyles?.length) {
      temp = updateList.map((item: LineItem, liIndex) => {
        return {
          ...item,
          pwwNumber: `1.${liIndex + 1}`,
        };
      });
    } else {
      orderStyles.forEach((style) => {
        const styleItems = updateList
          .filter((li) => li.styleId === style.id)
          .map((item: LineItem, liIndex) => {
            return {
              ...item,
              pwwNumber: `${style.number}.${liIndex + 1}`,
            };
          });
        temp = [...temp, ...styleItems];
      });
    }

    dispatch(
      setOrderLineItems(
        temp.sort((a, b) => UtilService.sortBy(a.number, b.number))
      )
    );
  };

  const onDeleteHandler = (close: () => void) => {
    UtilService.onPopupClose();
    close();

    dispatch(
      deleteLineItem(
        {
          orderId,
          lineItemId: lineItem.lineItemId,
        },
        onSuccessDeleteLineItem
      )
    );
  };

  const onFailedCurtainTopicsByEntity = () => {
    dispatch(setCurtainTab(CurtainTabsEnum.COLLABORATION_TAB));

    dispatch(setActiveEntityID(null));

    dispatch(
      setTopicTypeDefaultValue({
        value: TopicType.LINE_ITEM,
        label: 'Line item',
      })
    );

    dispatch(
      setTopicItemDefaultValue({
        value: lineItem.lineItemId ?? '',
        label: lineItem.lineItemDetails.code,
      })
    );

    dispatch(setCreateTopic(true));
  };

  const onSuccessGetLineItemChannels = (channels: IChannel[]) => {
    dispatch(setLineItemChannels(channels));
    const lineItemActiveChannel = channels?.find(
      (lineItemChannel: IChannel) =>
        lineItemChannel.entityId === lineItem.lineItemId
    );
    if (lineItemActiveChannel) {
      dispatch(setActiveChannel(lineItemActiveChannel));
    }
    dispatch(setActiveEntityID(lineItem.lineItemId ?? ''));
    dispatch(setActiveEntityType(TopicType.LINE_ITEM));
    dispatch(setActiveEntityName(lineItemActiveChannel?.name ?? ''));
    dispatch(setCurtainTab(CurtainTabsEnum.COLLABORATION_TAB));

    dispatch(setActiveTopic(null));
    dispatch(setCurtainComments(null));
    dispatch(
      getTopicsByEntity(
        { orderId, entityId: lineItem.lineItemId },
        undefined,
        onFailedCurtainTopicsByEntity
      )
    );
  };

  const onFailedGetLineItemChannels = () => {
    toast.error('Could not retrieve line item channels.');
  };

  const openCollaborationTopic = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    dispatch(
      setTopicItemDefaultValue({
        value: '',
        label: '',
      })
    );

    dispatch(setActiveLineItemID(null));

    dispatch(
      getChannels(
        {
          orderId,
          type: ChannelTypeEnum.LINE_ITEM,
        },
        onSuccessGetLineItemChannels,
        onFailedGetLineItemChannels
      )
    );
  };

  const isOrderHighlighted = () => {
    if (fromImport) {
      return selectedLineItemsToImport?.find(
        (selectedLineItem) =>
          selectedLineItem.lineItemId === lineItem.lineItemId
      );
    }
    const lineItemId = activeLineItemID || activeEntityID;
    return (
      lineItem.lineItemId === lineItemId ||
      lineItem.lineItemId === activeChannel?.entityId ||
      lineItem.lineItemId === topicItemDefaultValue?.value
    );
  };

  const onLineItemCheckedHandler = (checked: boolean) => {
    if (!isOrderSalesMaterial && checked) {
      // check parent style if order is with stilization
      dispatch(
        checkAsapItem({
          checked,
          itemId: lineItem.style.styleId,
          itemType: 'Styles',
        })
      );
    }

    // check current line item
    dispatch(
      checkAsapItem({
        checked,
        itemId: lineItem.lineItemId!,
        itemType: 'LineItems',
      })
    );

    // check line item child modifications
    if (lineItem.modifications?.length > 0) {
      lineItem.modifications.forEach((modification) => {
        dispatch(
          checkAsapItem({
            checked,
            itemId: modification.id,
            itemType: 'Modifications',
          })
        );
      });
    }
  };

  const getStyleNumber = () => {
    return orderStyles?.find((style) => style.id === lineItem.style.styleId)
      ?.number;
  };

  useEffect(() => {
    reset(getDefaultNumbersForLineItem());
  }, [lineItem.number, lineItem.clientLineItemNumber]);

  useEffect(() => {
    if (onExpand && lineItem && lineItem.modifications?.length > 0) {
      onExpand(lineItem.lineItemId, true);
    }
  }, [lineItem]);

  const getPositionEnds = () => {
    return enableExposedEnds
      ? parse(LineItemExposedEndsPositionEnum[lineItem.exposedEndPosition])
      : parse(LineItemPositionEnum[lineItem.finishEndPosition]);
  };

  return (
    <SingleLineContainer
      ref={draggableProvided?.innerRef}
      {...draggableProvided?.draggableProps}
    >
      {/* <button
        type="button"
        onClick={() =>
          dispatch(
            duplicateLineItem({ lineItemId: lineItem.lineItemId, orderId })
          )
        }
      >
        d
      </button> */}

      {canCheck && (
        <FloatingCheckbox>
          <Wrapper withTooltip={!!styleOverride} cursorDefault>
            <Checkbox
              id={lineItem.lineItemId}
              checked={checkedLineItems.some(
                (checkedLineItem) => checkedLineItem === lineItem.lineItemId
              )}
              onChange={(e) => onLineItemCheckedHandler(e.target.checked)}
              disabled={!!styleOverride}
            />

            {styleOverride && (
              <Tooltip position="right">
                Line Item(s) associated with the overriden Style cannot be
                selected when doing order duplication or creation of ASAP order.
              </Tooltip>
            )}
          </Wrapper>
        </FloatingCheckbox>
      )}

      {draggableProvided && (
        <DragButton
          {...draggableProvided?.dragHandleProps}
          disabled={!canEdit}
          iconColor={poloBlue}
          hoverIconColor={kashmirBlue}
        />
      )}

      <OrderLineItemContainer
        draggedItemHighlight={draggedItemHighlight}
        invalid={lineItem.isValid === undefined ? false : !lineItem.isValid}
        className={isOrderHighlighted() ? 'highlight' : ''}
        selectable={selectable}
      >
        {!fromImport && (lineItem.modifications?.length ?? 0) !== 0 ? (
          <ExpandButton
            onClick={(e) => {
              e.stopPropagation();

              if (onExpand) {
                onExpand(lineItem.lineItemId, !expanded);
              } else {
                onExpandLocal((prevState) => !prevState);
              }
            }}
            iconColor={altoTwo}
            hoverIconColor={lynch}
            className={
              expanded ?? expandedLocal
                ? 'expandable-box-button open'
                : 'expandable-box-button'
            }
            {...testId(OrderLineItemTestButtonEnum.EXPAND_MODIFICATIONS_BTN)}
          >
            <ArrowUpIcon />
          </ExpandButton>
        ) : (
          <ExpandButtonHolder />
        )}
        <Spacer w="5px" />

        <H6>{lineItem.pwwNumber}</H6>
        <Spacer w="16px" />
        <H6 fontSize={13}> CL</H6>
        <Spacer w="16px" />

        <FormErrorHolder>
          {draggableProvided ? (
            <StyledInput
              {...register(
                'clientNumber',
                onlyWholeDigitValidation({ min: 1 })
              )}
              aria-invalid={errors.clientNumber ? 'true' : 'false'}
              onFocus={() => dispatch(clearCurtain())}
              onBlur={(e) => {
                if (
                  !errors.clientNumber &&
                  +e.target.value !== lineItem.clientLineItemNumber
                ) {
                  onSubmitClientNumber(e.target.value);
                }

                if (errors.clientNumber) {
                  setValue(
                    'clientNumber',
                    lineItem.clientLineItemNumber.toString(),
                    { shouldValidate: true }
                  );
                }
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              disabled={!canEdit}
            />
          ) : (
            <StaticInputValue fontSize={12}>
              {lineItem.clientLineItemNumber
                ? lineItem.clientLineItemNumber
                : ''}
            </StaticInputValue>
          )}
        </FormErrorHolder>
        <Spacer w="16px" />
        <H6 fontSize={13}>L</H6>
        <Spacer w="16px" />

        <FormErrorHolder>
          {draggableProvided && totalItems ? (
            <StyledInput
              {...register(
                'number',
                lineItemNumberValidation({ required: true }, totalItems)
              )}
              aria-invalid={errors.number ? 'true' : 'false'}
              onFocus={() => dispatch(clearCurtain())}
              onBlur={(e) => {
                if (!errors.number && +e.target.value !== lineItem.number) {
                  onSubmitLineItemNumber(e.target.value);
                }

                if (errors.number) {
                  setValue('number', lineItem.number.toString(), {
                    shouldValidate: true,
                  });
                }
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              disabled={!canEdit}
            />
          ) : (
            <StaticInputValue fontSize={12}>{lineItem.number}</StaticInputValue>
          )}
        </FormErrorHolder>
        <Spacer w="16px" />
        <LineCodeContainer>
          <H6 fontSize={13}> QTY {lineItem.quantity}</H6>
        </LineCodeContainer>
        <Spacer w="8px" />
        <Wrapper
          middle
          flex
          onClick={() => {
            if (!fromImport) {
              dispatch(setActiveLineItemID(lineItem.lineItemId));
              dispatch(setCurtainTab(CurtainTabsEnum.DETAILS_TAB));
              dispatch(setTopicTypeDefaultValue(null));
              dispatch(setTopicItemDefaultValue(null));
            } else if (onLineItemClick && selectable) {
              onLineItemClick(lineItem);
            }
          }}
        >
          <ImageBox imageUrl={lineItem?.lineItemDetails?.imageUrl} />
          <Spacer w="16px" />

          <LineItemTitleContainer>
            <H6 fontSize={13}>
              {UtilService.generateLineItemNameWithDimensions(lineItem)}
            </H6>
            <P fontSize={12} color={mineShaft}>
              {lineItem.description}
            </P>
            {(lineItem.rightLegWidth > 0 || lineItem.rightLegDepth > 0) && (
              <>
                <P fontSize={12} color={mineShaft}>
                  {lineItem.width > 0 && `LW:${lineItem.width.toFixed(2)}, `}
                  {lineItem.depth > 0 && `LD:${lineItem.depth.toFixed(2)}`}
                </P>
                <P fontSize={12} color={mineShaft}>
                  {lineItem.rightLegWidth > 0 &&
                    `RW:${lineItem.rightLegWidth.toFixed(2)}, `}
                  {lineItem.rightLegDepth > 0 &&
                    `RD:${lineItem.rightLegDepth.toFixed(2)}`}
                </P>
              </>
            )}
          </LineItemTitleContainer>
          <Spacer w="10px" />

          {lineItem.style && (
            <LineItemStyleContainer>
              <H6 fontSize={13}>
                <ElipsisText maxWidth={130}>
                  #{getStyleNumber()} {lineItem.style.name}
                </ElipsisText>
              </H6>

              <P fontSize={12} color={mineShaft}>
                {lineItem.style.woodMaterial?.name ??
                  lineItem.style.materialGroup?.name}
                ,{' '}
                {lineItem.style.color?.name ??
                  lineItem.style.materialColor?.name}
                , {lineItem.style.doorStyle?.name ?? 'N/A'}
              </P>
            </LineItemStyleContainer>
          )}

          <Spacer w="18px" />

          <LineItemDetailsContainer flex middle between>
            {!isOrderSalesMaterial && (
              <>
                <Wrapper flex column middle>
                  <H6 fontSize={13}> Hinge</H6>
                  <P color={mineShaft} fontSize={12}>
                    {parse(LineItemHingePositionEnum[lineItem.hingePosition])}
                  </P>
                </Wrapper>

                <Spacer w="16px" />

                <Wrapper flex column middle>
                  <H6 fontSize={13}> {enableExposedEnds ? 'EE' : 'FE'}</H6>

                  <P color={mineShaft} fontSize={12}>
                    {getPositionEnds()}
                  </P>
                </Wrapper>
                <Spacer w="16px" />
              </>
            )}

            <Wrapper flex column middle>
              <H6 fontSize={13}> Unit Price</H6>
              <P color={mineShaft} fontSize={12}>
                ${lineItem.unitPrice}
              </P>
            </Wrapper>
          </LineItemDetailsContainer>

          <Spacer w="6px" />
          <WrapperRight flex middle>
            <LineLabelsContainer flex column alignEnd>
              {lineItem.priority > 0 && (
                <>
                  <ButtonClear onClick={openCollaborationTopic}>
                    <PriorityBadge
                      priority={lineItem.priority}
                      hoverColor={taupe}
                    />
                  </ButtonClear>
                  <Spacer h="4px" />
                </>
              )}

              <PerFactoryBadges
                perDrawing={lineItem.perDrawing}
                perFactoryDrawing={lineItem.perFactoryDrawing}
              />
            </LineLabelsContainer>

            <Spacer w="100px" />

            {!fromImport && (
              <ButtonsContainer flex justifyEnd>
                <ButtonIcon
                  iconColor={silverChaliceTwo}
                  hoverIconColor={kashmirBlue}
                  onClick={(e) => {
                    e.stopPropagation();
                    editLineItem();
                  }}
                  disabled={!canEdit}
                >
                  <Wrapper flex middle>
                    <PencilIcon width={18} height={28} />
                  </Wrapper>
                </ButtonIcon>
                <Spacer w="16px" />

                {!isOrderSalesMaterial && (
                  <>
                    <ButtonIcon
                      iconColor={silverChaliceTwo}
                      hoverIconColor={kashmirBlue}
                      onClick={(e) => {
                        e.stopPropagation();
                        onAttachmentClickHandler();
                      }}
                    >
                      <Wrapper flex middle>
                        <AttachmentIcon width={11} height={22} />
                        <Spacer w="5px" />
                        <P color={mineShaft} fontSize={12} fontWeight={700}>
                          {lineItem.numberOfAttachments}
                        </P>
                      </Wrapper>
                    </ButtonIcon>
                    <Spacer w="4px" />
                  </>
                )}

                <Wrapper position="relative">
                  <ButtonIcon
                    onClick={openCollaborationTopic}
                    ref={collaborationButtonRef}
                    iconColor={silverChaliceTwo}
                    hoverIconColor={kashmirBlue}
                  >
                    <Wrapper flex middle>
                      {lineItem.numberOfUnreadTopics > 0 ? (
                        <UnreadCollaborationIcon />
                      ) : (
                        <CommentIcon />
                      )}

                      <Spacer w="2px" />
                      <P color={mineShaft} fontSize={12} fontWeight={700}>
                        {lineItem.numberOfTopics}
                      </P>
                    </Wrapper>
                  </ButtonIcon>
                </Wrapper>

                <Spacer w="4px" />
                <MenuWithDropdown
                  trigger={
                    <ButtonIcon
                      iconColor={silverChaliceTwo}
                      hoverIconColor={kashmirBlue}
                      {...testId(
                        OrderLineItemTestThreeDotMenuButtonEnum.THREE_DOT_MENU_BTN
                      )}
                    >
                      <MoreInfoIcon />
                    </ButtonIcon>
                  }
                >
                  {(close: () => void) => (
                    <MoreInfoContainer flex column alignStart>
                      <MoreInfoButton
                        onClick={editLineItem}
                        {...testId(
                          OrderLineItemTestThreeDotMenuButtonEnum.EDIT_OR_VIEW_BTN
                        )}
                      >
                        {!canEdit ? 'View' : 'Edit'}
                      </MoreInfoButton>

                      <Wrapper withTooltip>
                        <MoreInfoButton
                          onClick={() => {
                            onDuplicateHandler(close);
                          }}
                          disabled={
                            !canEdit ||
                            !orderLineItemLimitNotReachedYet ||
                            duplicationInProgress
                          }
                        >
                          <Wrapper flex middle>
                            Duplicate
                            <Loader
                              size={16}
                              noSpacing
                              insideButton
                              hidden={!duplicationInProgress}
                            />
                          </Wrapper>
                        </MoreInfoButton>

                        {canEdit && !orderLineItemLimitNotReachedYet && (
                          <Tooltip position="left">
                            Line Items limit reached
                            {lineItem.style
                              ? ` for ${lineItem.style.name} style`
                              : ''}
                          </Tooltip>
                        )}
                      </Wrapper>

                      <MoreInfoButton
                        onClick={() => setIsReplaceModalVisible(true)}
                        disabled={!canEdit}
                        {...testId(
                          OrderLineItemTestThreeDotMenuButtonEnum.REPLACE_BTN
                        )}
                      >
                        Replace
                      </MoreInfoButton>

                      <Wrapper withTooltip>
                        <MoreInfoButton
                          color={valencia}
                          onClick={() => setIsDeleteModalVisible(true)}
                          disabled={!canEdit}
                          {...testId(
                            OrderLineItemTestThreeDotMenuButtonEnum.DELETE_BTN
                          )}
                        >
                          Delete
                        </MoreInfoButton>

                        {canEdit &&
                          isUserDealerOrSalesRep &&
                          !lineItem.dealerAvailable && (
                            <Tooltip position="left" bgColor={anzac}>
                              Line Item set by CSR! If you delete it - you will
                              not be able to bring it back to the order.
                            </Tooltip>
                          )}
                      </Wrapper>
                    </MoreInfoContainer>
                  )}
                </MenuWithDropdown>
              </ButtonsContainer>
            )}
          </WrapperRight>
        </Wrapper>
      </OrderLineItemContainer>

      {lineItem.isValid === undefined
        ? false
        : !lineItem.isValid && (
            <Wrapper>
              <Spacer h="5px" />
              <P fontSize={12} color={valencia} textAlign="right">
                {lineItem.invalidateReason}
              </P>
              <Spacer h="5px" />
            </Wrapper>
          )}

      {lineItem.dimUpPrice > 0 && (
        <IndentedContainer>
          <LineItemDimup
            dimUpPrice={lineItem.dimUpPrice}
            dimUp={lineItem.dimUp}
          />
        </IndentedContainer>
      )}

      {(lineItem.modifications?.length ?? 0) !== 0 &&
        (expanded ?? expandedLocal) && (
          <IndentedContainer>
            {lineItem.modifications.map((mod) => (
              <Modification
                key={mod.id}
                styleId={lineItem.styleId}
                lineItemId={lineItem.lineItemId}
                modification={mod}
                noActions
                canCheck={canCheck}
              />
            ))}
          </IndentedContainer>
        )}

      <ModalWrapper
        className="modal--full-screen"
        open={modalOpenedForLineItemId === lineItem.lineItemId}
        modal
        nested
        closeOnEscape={false}
        onOpen={UtilService.onPopupOpen}
        onClose={UtilService.onPopupClose}
        lockScroll
      >
        {(close: () => void) => <OrderLineItemsModal close={close} />}
      </ModalWrapper>

      <ConfirmationModal
        cancel={() => setIsReplaceModalVisible(false)}
        confirm={replaceLineItem}
        opened={isReplaceModalVisible}
        buttonText="Replace"
        title="Replace Line Item"
      >
        <P>
          Are you sure you want to replace{' '}
          <SPAN color={lynch} fontWeight={600}>
            #{lineItem.number} {lineItem.lineItemDetails.code}
          </SPAN>{' '}
          line item on this order?
        </P>
      </ConfirmationModal>

      <ConfirmationModal
        cancel={() => setIsDeleteModalVisible(false)}
        confirm={onDeleteHandler}
        opened={isDeleteModalVisible}
        title="Confirmation"
      >
        {isUserDealerOrSalesRep && !lineItem.dealerAvailable && (
          <>
            <Info type="warning">
              Line Item set by CSR! If you delete it - you will not be able to
              bring it back to the order.
            </Info>

            <Spacer h="20px" />
          </>
        )}

        <P>Are you sure you want to delete this line item?</P>

        <Spacer h="20px" />

        {lineItem.numberOfTopics > 0 && (
          <P>
            The topic{lineItem.numberOfTopics !== 1 ? 's' : ''} for the deleted
            Item (Line Item or a Style) is at the bottom of the Topic List,
            marked as deleted.
          </P>
        )}
      </ConfirmationModal>
    </SingleLineContainer>
  );
};

OrderLineItem.defaultProps = {
  changeLineItemPosition: undefined,
  draggableProvided: undefined,
  canCheck: false,
  totalItems: undefined,
  index: undefined,
  draggedItemHighlight: undefined,
  expanded: undefined,
  onExpand: undefined,
  fromImport: undefined,
  onLineItemClick: undefined,
  selectable: true,
  styleOverride: null,
};

export { OrderLineItem };
